import './style.scss';
import { ReactComponent as HomePanda } from '../../../images/home-panda.svg';
import BorderedText from '../../bordered-text';

const MainBanner = () => {
  return (
    <div className="main-banner">
      <div className="main-banner__container">
        <div className="left-part">
          <div className="text-container">
            <h1>
              Your Free Personal AI Therapist
            </h1>

            <p>Measure & improve your mental health in real time with your personal AI chat bot. No sign up. Available 24/7. Daily insights just for you!</p>

          </div>
        </div>
        <div className="right-part">
          <div className="phone-background">
            <center>
              <HomePanda className="chat-panda" />
              <a href="https://chat.earkick.com">
                <BorderedText
                  className="chat-button"
                  textColor="#FFFFFF"
                  backgroundColor="#A83A3D"
                  text="Chat With Panda"
                  rotate="0"
                  innerClassName="chat-button"
                />
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
